<template>
  <div ref="root">
    <div class="alert alert-info" v-show="client.id != plan.client_id">
      Este es el plan de tratamiento de
      <b>{{ plan.client_name }}</b>
    </div>

    <entity-head-info
      :entity_id="entity_id"
      class="ml-1 mb-2 d-none d-print-flex"
    >
      <div>Telefonos: {{ dental_config.phone_contact }}</div>
      <div>Cliente: {{ plan.client_name }}</div>
      <p v-show="proform.created_at">
        Proforma Emitida el {{ proform.created_at | date }}
      </p>
    </entity-head-info>

    <div class="card mb-2 py-1 d-print-none">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link disabled" href="#" @click.prevent="1"
            >Plan de tratamiento</a
          >
        </li>
        <li class="nav-item dropdown" v-show="config.edit">
          <a
            class="nav-link dropdown-toggle text-muted"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-cog"></i>
            opciones
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              href="#"
              v-show="!plan.initiated"
              @click.prevent="$refs.dInitTreat.show()"
              >Iniciar tratamiento</a
            >
            <a
              class="dropdown-item"
              href="#"
              v-show="!plan.ended"
              @click.prevent="$refs.endThis.show()"
            >
              Finalizar tratamiento
            </a>
            <a
              class="dropdown-item"
              href="#"
              v-show="plan.ended"
              @click.prevent="$refs.elPreReactive.show()"
            >
              Reactivar
            </a>
            <a
              class="dropdown-item"
              href="#"
              v-show="!plan.ended"
              @click.prevent="$refs.dDiscount.show()"
              >Descuento</a
            >
            <a
              class="dropdown-item"
              href="#"
              v-show="!plan.ended"
              @click.prevent="showPayDialog()"
              >Registrar Pago</a
            >
            <a
              class="dropdown-item"
              href="#"
              v-show="!plan.ended"
              @click.prevent="$refs.dAddCost.show()"
            >
              Mora / Visa
            </a>
            <a
              class="dropdown-item"
              href="#"
              v-show="!plan.ended"
              @click.prevent="
                $refs.dFinancing.show();
                $refs.financing.load();
              "
              >Financiacion</a
            >
          </div>
        </li>
        <li class="ml-auto nav-item dropdown">
          <a
            class="nav-link dropdown-toggle text-muted"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-print"></i> Impresion
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a href="#" class="dropdown-item" @click.prevent="print()">
              Imprimir
            </a>
            <a href="#" class="dropdown-item" @click.prevent="print(true)">
              Imprimir para firmar
            </a>
          </div>
        </li>
      </ul>
    </div>

    <SingleViewTr
      @changePlan="getList()"
      :reg="plan"
      class="mb-2"
      :details="details"
    ></SingleViewTr>

    <Odontogram
      ref="elMainOdontogram"
      :disabled="true"
      class="mb-2"
      @piece="filterPiece($event)"
    >
      <template slot="history">
        <span></span>
      </template>
    </Odontogram>

    <button
      class="btn btn-sm btn-warning mr-1 mb-2"
      v-show="showProform"
      @click="$router.push($route.path)"
      title="Click para remover filtro"
    >
      <span>Proforma: {{ proform_id }}</span>
      <i class="ml-2 fa fa-close d-print-none"></i>
    </button>

    <button
      class="btn btn-sm btn-warning mr-1 mb-2"
      v-show="piece"
      @click="piece = null"
      title="Click para remover filtro"
    >
      <span>Pieza: {{ piece }}</span>
      <i class="ml-2 fa fa-close d-print-none"></i>
    </button>

    <div class="d-print-none mb-2" v-show="config.edit">
      <app-button-load
        class="mr-1 btn-success"
        @click="showDialogtoAddT()"
        v-show="!plan.ended && !proform_id"
      >
        <i class="fa fa-teeth"></i> Odontograma
      </app-button-load>

      <button
        class="btn btn-success mr-1"
        @click="showPayDialog()"
        v-show="!plan.ended"
      >
        Registrar Pago
      </button>

      <!-- <button class="btn btn-secondary mr-1" @click="print()">
        <i class="fas fa-print"></i> Imprimir
      </button> -->
    </div>

    <!-- <alert-expiration
      v-show="!plan.initiated"
      class="my-2"
      :treatment_plan_id="id"
    ></alert-expiration> -->

    <div class="alert alert-danger" v-show="showProform">
      Fecha de vencimiento de proforma: <b>{{ proform.expiration | date }}</b>
    </div>

    <table
      class="table table-bordered table-sm bg-white"
      :class="{ 'table-responsive': $store.state.display.sm }"
    >
      <thead>
        <tr class="text-center">
          <th class="d-print-none"></th>
          <th>Tratamiento</th>
          <th>Pieza</th>
          <th>-%</th>
          <th>Precio</th>
          <th class="d-print-none">Est. pago</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="l in filterDetails">
          <tr
            :key="l.id"
            @mouseleave="hideMenu(l)"
            :class="{ 'table-danger': l.to_make && !l.maked }"
          >
            <td class="text-center d-print-none" @mouseenter="showMenu(l)">
              <DMenu
                @deleteSelected="$refs.elDeleteGroup.show()"
                :ref="'dm' + l.id"
                @evolution="showDialogToEvol()"
                @quote="showToQuote()"
                :l="l"
                :listToEvol="listToEvol"
                @motrarOcultar="
                  l._show_details = !l._show_details;
                  $forceUpdate();
                "
                @toDelete="
                  $refs.dDelDet.show();
                  $refs.dDelDet.val = l.id;
                "
                @inEvolution="inEvolution(l.id)"
                @prePaid="prePaid(l.id, 1)"
                @noPrePaid="prePaid(l.id, 2)"
                @reqLaboratory="showReqLabJob(l)"
                @markToMake="
                  l.to_make = !l.to_make;
                  markToMake(l.id);
                "
              ></DMenu>
              <CheckMakeStatus
                :maked="l.maked"
                :toMake.sync="l.toMake"
              ></CheckMakeStatus>
              <br />
              <!-- <SupplierJobStatus
                v-if="l.supplier_job"
                :paid_out="l.supplier_job.paid_out"
              ></SupplierJobStatus> -->
            </td>
            <td>
              <!-- <i -->
              <!--   class="fas" -->
              <!--   :class="{ -->
              <!--     'fa-file-image': l.product_type_id == 2, -->
              <!--     'fa-file-text': l.product_type_id == 1 -->
              <!--   }" -->
              <!-- ></i> -->
              <i
                title="Registradas citas anteriores"
                class="far fa-calendar-check"
                v-show="l.quotes.length > 0"
              ></i>
              &nbsp;
              <span :title="`${l.id}-${l.product_id}`">
                {{ l.detail_name || l.product_name }}
              </span>
              <br />
              <b
                v-show="isToday(l.quote_time) && l.to_make && !l.maked"
                class="text-danger"
              >
                A las {{ l.quote_time | timeFormat }} con
                {{ l.quote_employee }}
              </b>

              <b
                v-show="
                  l.quote_time &&
                    l.quote_employee &&
                    !isToday(l.quote_time) &&
                    l.to_make &&
                    !l.maked
                "
                class="text-info"
              >
                Cita para el {{ l.quote_time | dateFormat }} con
                {{ l.quote_employee }}
              </b>
            </td>

            <td class="text-center">
              <ToothPiece
                v-for="l1 in l.th_list"
                :key="'m' + l1"
                :piece="l1"
              ></ToothPiece>
            </td>
            <td class="text-center">
              <app-span-input
                :disabled="!config.edit || l.paid_out || l.amount_paid != 0"
                :value="l.discount"
                @input="setDiscount(l, $event)"
                :sm="true"
                >{{ (l.discount / 100) | percent }}</app-span-input
              >
            </td>
            <td class="text-right">
              <app-span-money
                v-show="!l.maked && !l.paid_out && l.amount_paid > 0"
                :moneyCode="plan.money_code"
                class="text-success"
                :quantity="l.amount_paid"
              ></app-span-money>
              <app-span-input
                :disabled="!config.edit || l.paid_out || l.amount_paid != 0"
                :value="l.unit_price"
                @input="setPriceDetail(l, $event)"
                :sm="true"
              >
                <app-span-money
                  :quantity="l.unit_price"
                  :moneyCode="plan.money_code"
                ></app-span-money>
              </app-span-input>
              <app-span-money
                v-show="l.maked && !l.paid_out && l.amount_paid > 0"
                :moneyCode="plan.money_code"
                class="text-danger"
                :quantity="l.unit_price - l.amount_paid"
              ></app-span-money>
            </td>
            <td class="text-center d-print-none">
              <span style="font-size: 1.5em">
                <i
                  v-show="l.financied"
                  title="Financiado"
                  class="text-info fa fa-lock"
                ></i>
                <span v-show="!l.financied">
                  <i
                    v-show="l.paid_out || l.amount_paid >= l.unit_price"
                    title="Pagado"
                    class="text-success fa fa-circle"
                  ></i>
                  <i
                    v-show="
                      !(l.paid_out || l.amount_paid >= l.unit_price) && !l.maked
                    "
                    title="No Pagado"
                    class="fa fa-circle"
                  ></i>
                  <i
                    v-show="
                      l.maked && !(l.paid_out || l.amount_paid >= l.unit_price)
                    "
                    title="debe este causa"
                    class="fa fa-circle"
                    :class="{
                      'text-danger': l.amount_paid == 0,
                      'text-warning': l.amount_paid < l.unit_price,
                    }"
                  ></i>
                </span>
              </span>
            </td>
          </tr>
          <tr v-show="l._show_details || !l.maked" :key="`${l.id}quotes`">
            <td class="p-0"></td>
            <td colspan="5" class="p-0">
              <table
                class="table table-sm table-bordered"
                v-if="l.quotes.length > 0"
              >
                <tr class="table-warning">
                  <th>FECHA</th>
                  <th>DETALLE DE CITA</th>
                  <th>DOCTOR(A)</th>
                </tr>
                <tr v-for="(l1, i1) in l.quotes" :key="`${i1}quote`">
                  <td :title="l1.ini_date">
                    {{ l1.ini_date | dateTimeFormat }}
                  </td>
                  <td>{{ l1.commentary }}</td>
                  <td>{{ l1.employee_name }}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr
            :key="'_' + l.id"
            style="display:none;"
            :ref="'dd' + l.id"
            v-show="l.employee_id && l._show_details"
          >
            <td></td>
            <td colspan="5">
              <span>Realizado por: {{ l.employee_name }}</span>
              <br />Fecha:
              <span class="text-muted">{{ l.maked_date }}</span>
              <br />
              <span>
                <i class="fa fa-comment"></i>
                {{ l.comment }}
              </span>
            </td>
          </tr>
        </template>
        <tr>
          <th class="d-print-none"></th>
          <th colspan="3" class="text-success">Total</th>
          <th class="text-right">
            <app-span-money
              :quantity="plan.without_discount"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </th>
        </tr>
        <tr>
          <th class="d-print-none"></th>
          <th colspan="3" class="text-danger">Descuento(%)</th>
          <th class="text-danger text-right">
            <app-span-money
              :quantity="plan.total_discount"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </th>
        </tr>
        <tr>
          <th class="d-print-none"></th>
          <th colspan="3" class="text-success">Presupuesto</th>
          <th class="text-right">
            <app-span-money
              :quantity="plan.sum_budget"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </th>
        </tr>
        <tr>
          <th class="d-print-none"></th>
          <th colspan="3">Pagado</th>
          <th class="text-right">
            <app-span-money
              :quantity="pf(plan.payment) + pf(plan.paid_out_amount)"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </th>
        </tr>
        <tr>
          <th class="d-print-none"></th>
          <th colspan="3">Realizado</th>
          <th class="text-right">
            <app-span-money
              :quantity="plan.maked_amount"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </th>
        </tr>
        <tr>
          <th class="d-print-none"></th>
          <th colspan="3">Saldo</th>
          <th class="text-right">
            <app-span-money
              :quantity="pf(plan.payment) + pf(plan.prepaid_amount)"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </th>
        </tr>
        <tr>
          <th class="d-print-none"></th>
          <th
            :class="{
              'text-danger': pf(plan.payment) - pf(plan.debt_amount) < 0,
            }"
            colspan="3"
          >
            Saldo Pendiente
          </th>
          <th class="text-right">
            <app-span-money
              :quantity="pf(plan.payment) - pf(plan.debt_amount)"
              :moneyCode="plan.money_code"
            ></app-span-money>
          </th>
        </tr>
      </tbody>
    </table>

    <div class="d-print-none mt-2 mb-2" v-show="config.edit">
      <app-button-load
        class="mr-1 btn-success"
        @click="showDialogtoAddT()"
        v-show="!plan.ended"
      >
        <i class="fa fa-teeth"></i> Odontograma
      </app-button-load>

      <button
        class="btn btn-success mr-1"
        @click="showPayDialog()"
        v-show="!plan.ended"
      >
        Registrar Pago
      </button>
    </div>

    <table
      class="table table-bordered table-sm bg-white d-print-none"
      :class="{ 'table-responsive': $store.state.display.sm }"
    >
      <tr class="d-print-none">
        <th colspan="5" class="text-center">
          PROTESIS Y TRABAJOS DE LABORATORIO
        </th>
      </tr>
      <tr class="text-center">
        <th></th>
        <th>Tratamiento</th>
        <th>Pieza</th>
        <th>Estado</th>
        <th>Laboratorio</th>
      </tr>
      <template v-for="l in laboratory_jobs">
        <tr :key="l.id">
          <td>
            <SupplierJobStatus :paid_out="l.paid_out"></SupplierJobStatus>
          </td>
          <td>
            <span :title="l.id">{{ l.product_name }}</span>
            <div class="d-print-none">
              Comentarios:
              <div
                class="text-muted"
                v-show="l.comments.length > 0"
                :title="`${l1.created_at} ${supplierStates[l1.status]}`"
                v-for="l1 in l.comments"
                :key="l1.id"
              >
                - {{ l1.comment }}
              </div>
            </div>
          </td>
          <td>
            <ToothPiece
              v-for="l1 in l.th_list"
              :key="'m' + l1"
              :piece="l1"
            ></ToothPiece>
          </td>
          <td>
            {{ supplierStates[l.status] }}
          </td>

          <td>
            {{ l.supplier_name }}
          </td>
          <td>
            <button
              class="btn btn-sm btn-light"
              @click="
                $refs.elPrevDelLabJob.show();
                $refs.elPrevDelLabJob.val = l.id;
              "
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </template>
    </table>

    <div class="d-print-none">
      <quote-list :treatmentPlanId="plan.id"></quote-list>
    </div>

    <div v-show="includeSignatures" class="pt-5">
      <div class="row d-none d-print-flex">
        <div class="col d-flex">
          <div class="text-center mx-auto">
            <hr class="my-3" />
            {{ plan.client_name }}
          </div>
        </div>
        <div class="col d-flex" v-if="config.employee_signature">
          <div class="text-center mx-auto">
            <hr class="my-3" />
            {{ plan.employee_name }}
          </div>
        </div>
      </div>
    </div>

    <app-modal-yn
      ref="elPrevDelLabJob"
      @yes="deletelabJob($refs.elPrevDelLabJob.val)"
    >
      <p>Eliminar este trabajo de laboratorio</p>
    </app-modal-yn>

    <app-modal-yn
      ref="dDelDet"
      @yes="disDetail($refs.dDelDet.val)"
      @no="$refs.dDelDet.hide()"
    >
      <p v-if="$refs.dDelDet && $refs.dDelDet.val">
        Seguro de eliminar el tratamiento?
      </p>
    </app-modal-yn>

    <app-modal-basic ref="dAddPrest" :lg="true" :hideIfClickBackground="false">
      <div class="dropdown d-flex">
        <button
          class="btn btn-sm btn-light"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fa fa-cog"></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#" @click.prevent="seeOdo = 1">
            <i class="fas fa-teeth"></i> Odontograma
          </a>
          <a class="dropdown-item" href="#" @click.prevent="seeOdo = 2">
            <i class="fas fa-notes-medical"></i> Odontograma Comercial
          </a>
        </div>
      </div>
      <div v-show="seeOdo == 1">
        <div ref="odoContainer"></div>
        <app-button-submit @click="loadPrests()">Terminar</app-button-submit>
      </div>
      <ComOdoToTreatment
        @submitted="
          $refs.dAddPrest.hide();
          getList();
        "
        v-show="seeOdo == 2"
        ref="com_odontogram"
        :money_code="plan.money_code"
        :treatment_plan_id="plan.id"
      ></ComOdoToTreatment>
    </app-modal-basic>

    <app-modal-yn
      ref="dInitTreat"
      @yes="initTreatment()"
      @no="$refs.dInitTreat.hide()"
    >
      <p>Iniciar Tratamiento?</p>
    </app-modal-yn>

    <app-modal-basic ref="dProform" :xl="true">
      <div ref="proformContainer"></div>
      <button @click="makeNewProform()" class="btn btn-primary">
        Copiar en Nueva Proforma
      </button>
    </app-modal-basic>

    <app-modal-basic ref="dEvolution">
      <h4>Evolucionar</h4>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>TRATAMIENTO</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in listToEvol" :key="l.id">
            <td>{{ l.product_name }}</td>
          </tr>
        </tbody>
      </table>
      <div class="form-group">
        <label for>Profesional</label>
        <SelectEmployee ref="selEmp" v-model="reg.employee_id"></SelectEmployee>
      </div>
      <div class="form-group">
        <label for>Comentario</label>
        <app-textarea v-model="reg.comment"></app-textarea>
      </div>
      <app-button-submit @click="evolution()"></app-button-submit>
    </app-modal-basic>

    <app-modal-basic ref="dToPay">
      <ToPay
        ref="toPay"
        :details="details"
        :plan="plan"
        @paidOut="afterPaid()"
      ></ToPay>
    </app-modal-basic>

    <app-modal-basic ref="elModalSimplePay">
      <SimplePay
        ref="elSimplePay"
        :details="details"
        :plan="plan"
        @paidOut="afterPaid()"
      ></SimplePay>
    </app-modal-basic>

    <app-modal-basic ref="dReqJob">
      <FormRequestJob
        ref="formReq"
        @submitted="
          getList();
          $refs.dReqJob.hide();
        "
      ></FormRequestJob>
    </app-modal-basic>

    <app-modal-basic ref="dAddCost">
      <FormAddPreset
        ref="formAddPreset"
        :money_code="plan.money_code"
        :treatment_plan_id="plan.id"
        @submitted="
          $refs.dAddCost.hide();
          getList();
        "
      ></FormAddPreset>
    </app-modal-basic>

    <app-modal-basic ref="dFinancing" :lg="true">
      <TreatmentFinancing
        :details="details"
        ref="financing"
        :plan="plan"
        :treatment_plan_id="id"
      ></TreatmentFinancing>

      <button
        class="btn btn-primary mt-1"
        @click="
          $refs.dFormCreate.show();
          $refs.formMkFin.reset();
        "
      >
        Nueva financiacion
      </button>
    </app-modal-basic>

    <app-modal-basic ref="dFormCreate" :lg="true">
      <h4>FINANCIAR</h4>
      <FormCreate
        ref="formMkFin"
        :plan="plan"
        :details="details"
        @submitted="
          $refs.dFormCreate.hide();
          getList();
          $refs.financing.load();
        "
        :treatment_plan_id="plan.id"
      ></FormCreate>
    </app-modal-basic>

    <app-modal-yn ref="endThis" @yes="endThis()">
      <p>Finalizar el tratamiento?</p>
    </app-modal-yn>

    <DialogDiscount
      ref="dDiscount"
      :treatment_plan_id="id"
      @submitted="getList()"
    ></DialogDiscount>
    <app-modal-basic ref="dReqLabJob">
      <form-lab-job
        ref="formLabJob"
        @submitted="
          $refs.dReqLabJob.hide();
          getList();
        "
        :default_employee_id="plan.employee_id"
      ></form-lab-job>
    </app-modal-basic>

    <app-modal-yn ref="elDeleteGroup" @yes="deleteSelected()">
      Eliminar los tratamientos seleccionados
    </app-modal-yn>

    <app-modal-yn ref="elPreReactive" @yes="reactivate()">
      Reactivar Tratamiento
    </app-modal-yn>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import Odontogram from "../odontogram/Odontogram.vue";
import SingleViewTr from "./SingleView.vue";
import CheckMakeStatus from "./CheckMakeStatus";
// import Proform from "../proforms/Form";
import SelectEmployee from "../../admin-module/employees/Select";
import ToPay from "./ToPay.vue";
import { pieceName } from "../odontogram/tooth-utils";
import ToothPiece from "../odontogram/ToothPiece.vue";
import ComOdoToTreatment from "./ComOdoToTreatment";
import { mapState } from "vuex";
import FormRequestJob from "./FormRequestJob";
import FormAddPreset from "./FormAddPreset";
import Vue from "vue";
import TreatmentFinancing from "../financings/TreatmentFinancing";
import FormCreate from "../financings/FormCreateFromtreatment";
import SupplierJobStatus from "../laboratory-jobs/SupplierJobStatus";
import DialogDiscount from "./DialogDiscount";
import entityHeadInfo from "../../admin-module/entitys/HeadInfo";
import FormLabJob from "./form-lab-job";
// import alertExpiration from "../proforms/alert-expiration";
import DMenu from "./DropdownMenuDetail";
import { myMoment } from "../../utils/myMoment";
// import FormQuote from "../quotes/Form";
// import selectDuration from "../quotes/select-duration";
import quoteList from "../quotes/List";
import SimplePay from "./SimplePay.vue";

export default {
  components: {
    quoteList,
    // selectDuration,
    // FormQuote,
    // alertExpiration,
    FormLabJob,
    SupplierJobStatus,
    FormCreate,
    entityHeadInfo,
    ComOdoToTreatment,
    SingleViewTr,
    CheckMakeStatus,
    SelectEmployee,
    ToPay,
    ToothPiece,
    FormRequestJob,
    FormAddPreset,
    TreatmentFinancing,
    DialogDiscount,
    DMenu,
    Odontogram,
    SimplePay,
  },
  props: {
    id: {},
  },
  data: () => ({
    includeSignatures: true,
    seeOdo: 1,
    reg: {},
    details: [],
    plan: {},
    odo_discount: 0,
    proform_id: undefined,
    laboratory_jobs: [],
    showProform: false,
    // proformExpiration: "",
    proform: {},
    piece: null,
  }),
  watch: {
    $route() {
      this.getValuesFromQueryParams();
      this.reload();
    },
  },
  computed: {
    filterDetails() {
      return this.details.filter((x) => {
        if (this.piece && !x.th_list.includes(this.piece)) {
          return false;
        }
        return true;
      });
    },
    ...mapState({
      supplierStates: (s) => s.config.logistic.supplier_jobs.states,
      client: (s) => s.dental.client,
      entity_id: (s) => s.user.entity_id,
      config: (s) => s.config.service.treatments,
      dental_config: (s) => s.config.dental.treatments,
    }),
    listToEvol() {
      return this.details.filter((x) => x.toMake);
    },
    total() {
      let total = 0;
      this.details.map((x) => {
        total += parseFloat(x.unit_price);
      });
      return total;
    },
  },

  created() {
    this.getValuesFromQueryParams();
  },

  methods: {
    isToday(date) {
      return myMoment(date, "YYYY-MM-DD").isSame(
        myMoment().format("YYYY-MM-DD")
      );
      // return true;
    },
    showToQuote() {
      // this.$refs.elModalQuote.show();
      // this.$refs.elFormQuote.setEmployeeId(this.plan.employee_id);
      this.$router.push({
        path: `/dental-clinic/weekly-agenda/${this.plan.employee_id}`,
        query: {
          client_id: this.plan.client_id,
          treatment_details: this.listToEvol
            .map((x) => x.id)
            .reduce((a, b) => {
              return a + "," + b;
            }),
        },
      });
    },
    deletelabJob(lab_job_id) {
      DentalClinicService.deleteLaboratoryJob(lab_job_id).then(() => {
        this.reload();
      });
    },
    reload() {
      this.getList();
    },
    getValuesFromQueryParams() {
      this.proform_id = this.$route.query.proform_id || undefined;
    },
    showReqLabJob(l) {
      this.$refs.dReqLabJob.show();
      this.$refs.formLabJob.load(l);
    },
    endThis() {
      DentalClinicService.saveTreatmentPlan({
        id: this.id,
        finalize: 1,
      }).then(() => {
        this.getList();
        this.$refs.endThis.hide();
      });
    },
    print(includeSignatures = false) {
      this.includeSignatures = includeSignatures;
      setTimeout(() => {
        window.print();
      }, 300);
    },
    makeNewProform() {
      DentalClinicService.saveProForm({
        copy_proform: 1,
        proform_id: this.plan.proform_id,
      }).then((res) => {
        this.$router.push("/dental-clinic/proforms/" + res);
      });
    },
    initTreatment() {
      DentalClinicService.setTreatmentPlan({
        id: this.id,
        letsToInit: 1,
      }).then(() => {
        this.getList();
        this.$refs.dInitTreat.hide();
      });
    },
    setToChangeDiscount(l) {
      if (!l.paid_out) {
        l.to_set_dis = true;
        this.$forceUpdate();
      }
    },
    loadProform() {
      if (!this.$refs.proform.reg.id) {
        DentalClinicService.getProform(this.plan.proform_id).then((res) => {
          this.$refs.proform.loadProform(res);
        });
      }
    },
    setPriceDetail(l, price) {
      DentalClinicService.setPriceTreatmentDetail(l.id, price).then(() => {
        this.getList();
      });
    },
    setDiscount(l, discount) {
      DentalClinicService.setDiscountTreatmentDetail(l.id, discount).then(
        () => {
          this.getList();
        }
      );
    },
    inEvolution(id) {
      DentalClinicService.inEvolutionTreatmentDetail(id).then(() =>
        this.getList()
      );
    },

    pf: (val) => parseFloat(val),
    prePaid(detail_id, mode = 1) {
      var formData = {
        id: detail_id,
        prepaid: true,
      };
      formData[mode == 1 ? "add_prepaid" : "remove_prepaid"] = 1;
      DentalClinicService.setTreatmentPlanDetail(formData).then(() => {
        this.getList();
      });
    },

    round(val, dec) {
      val = parseFloat(val);
      return val.toLocaleString(undefined, { minimumFractionDigits: dec });
    },
    showMenu(l) {
      // if (!this.config.edit) {
      //   return;
      // }
      // this.$refs["dm" + l.id][0].classList.add("show");
      this.$refs["dm" + l.id][0].showMenu();
    },
    hideMenu(l) {
      // this.$refs["dm" + l.id][0].classList.remove("show");
      this.$refs["dm" + l.id][0].hideMenu();
    },
    // $,
    afterPaid() {
      this.getList();
      if (!this.$refs.toPay.payNow) {
        this.$refs.dToPay.hide();
        this.$store.dispatch("dental/reloadClient");
      }
    },
    pieceName,
    disDetail(id) {
      DentalClinicService.deleteTreatmentPlanDetail(id).then(() => {
        this.getList();
        this.$refs.dDelDet.hide();
      });
    },
    loadPrests() {
      DentalClinicService.loadOdontogramToTreatment({
        odontogram_id: this.$refs.odontogram.odontogram_id,
        treatment_plan_id: parseInt(this.id),
        discount: this.odo_discount,
      }).then(() => {
        this.getList();
        this.$refs.dAddPrest.hide();
      });
    },
    filterPiece(piece) {
      // console.log(`i have to filter ${piece}`);

      this.piece = parseInt(piece);
    },
    getList() {
      return new Promise((rsv) => {
        DentalClinicService.getTreatmentPlan(this.id, {
          proformId: this.proform_id,
          addDetails: 1,
          addLaboratoryJobs: 1,
        }).then((res) => {
          this.plan = res;
          this.details = res.details;
          this.laboratory_jobs = res.laboratory_jobs;

          let complete = true;
          res.details.map((x) => {
            if (!x.maked) {
              complete = false;
            }
          });
          if (complete && !this.plan.ended && res.details.length > 0) {
            // this.$refs.endThis.show();
          }

          this.$refs.elMainOdontogram.reset();
          this.$refs.elMainOdontogram.loadStatusList(res.teeth_history);

          if (res.proform) {
            // this.showProform= !myMoment(res.proform.created_at).isSame(
            //   res.created_at,
            //   "date"
            // );

            this.showProform = true;
            this.proform = res.proform;
            // this.proformExpiration = myMoment(res.proform.expiration).format(
            //   "L"
            // );
          } else {
            this.showProform = false;
          }
          rsv();
        });
      });
    },
    markToMake(detailId) {
      DentalClinicService.saveTreatmentPlanDetail({
        id: detailId,
        markToMake: 1,
      }).then(() => {
        this.$store.dispatch("showSuccess", "Marcado guardado");
      });
    },
    showDialogToEvol() {
      this.$refs.dEvolution.show();
      this.$refs.selEmp.setValueFromId(this.plan.employee_id);
    },
    evolution() {
      DentalClinicService.saveEvolutionTreatment({
        list: this.listToEvol.map((x) => x.id),
        employee_id: this.reg.employee_id,
        treatment_plan_id: this.id,
        comment: this.reg.comment,
      }).then(() => {
        this.$refs.dEvolution.hide();
        this.getList();
      });
    },
    reactivate() {
      DentalClinicService.reactivateTreatmentPlan(this.id).then(() => {
        this.plan.ended = false;
      });
    },
    deleteSelected() {
      DentalClinicService.deleteTreatmentPlanDetails({
        list: this.listToEvol.map((x) => x.id),
      }).then(
        () => {
          this.getList();
        },
        () => {
          this.$store.dispatch(
            "showError",
            `No se puede eliminar todos los tratamientos`
          );
        }
      );
    },
    showDialogtoAddT() {
      if (!this.$refs.odontogram) {
        // si el odontograma aun no existe
        var OdontogramComponent = Vue.extend(Odontogram);
        var odoInstance = new OdontogramComponent({
          propsData: {
            showPrices: false,
            canSetName: false,
            requiredSetProduct: true,
          },
          store: this.$store,
        });
        odoInstance.$mount();
        odoInstance.setClientId(this.plan.client_id);
        // switch (this.plan.specialty_id) {
        //   case 1:
        //     odoInstance.group = 1;
        //     break;
        //   case 2:
        //     odoInstance.group = 2;
        //     break;
        //   case 3:
        //     odoInstance.group = 4;
        //     break;
        //   case 4:
        //     odoInstance.group = 3;
        //     break;
        //   default:
        //     break;
        // }

        this.$refs.odoContainer.appendChild(odoInstance.$el);

        this.$refs.odontogram = odoInstance;

        // this.$refs.odontogram.group = 2;
      }

      this.$refs.dAddPrest.show();
      this.$refs.com_odontogram.reset();
      let lastDetailsId = Math.max(...this.details.map((x) => x.id)) || "";
      this.$refs.odontogram.setName("T-" + this.plan.id + "-" + lastDetailsId);
      this.$refs.odontogram.getList();
    },
    showPayDialog() {
      if(this.$store.state.config.dental.treatments.simple_pay) {
        this.$refs.elModalSimplePay.show();
        this.$refs.elSimplePay.show();
      } else {
        this.$refs.dToPay.show();
        this.$refs.toPay.show();
      }

    },
  },
  mounted() {
    this.getList();
    this.$store.commit("dental/setOdoGroup", 1 /**ODONTOLOGIA */);
    // this.$refs.elMainOdontogram.setName("T-" + this.id);
    // this.$refs.elMainOdontogram.reload();
  },
};
</script>

<style></style>
